import { MarchandData } from '@innedit/innedit';
import React, { FC } from 'react';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import List from '../../../../../CMS/containers/Boutique/components/new/List';
import marchand from '../../params.json';
import Item from './Item';

const MarchandList: FC<BoutiqueProps> = ({ boutique }) => (
  <CMSView>
    <List
      item={Item}
      itemPathnamePrefix="/boutique/marchands/"
      model={new MarchandData({ boutique, params: marchand })}
      title="Marchands"
    />
  </CMSView>
);

export default requireBoutique(MarchandList);
