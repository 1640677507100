import { MarchandData } from '@innedit/innedit';
import React, { FC } from 'react';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import Form from '../../../../../CMS/containers/Boutique/components/new/Form';
import params from '../../params.json';

const MarchandForm: FC<BoutiqueProps & { docId: string }> = ({
  boutique,
  docId,
  user,
}) => (
  <CMSView>
    <Form
      docId={docId}
      itemPathnamePrefix="/boutique/marchands/"
      model={new MarchandData({ boutique, params })}
      name="marchand"
      user={user}
    />
  </CMSView>
);

export default requireBoutique(MarchandForm);
