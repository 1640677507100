import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import MarchandForm from '../../core/sysext/Boutique/containers/Marchand/components/Form';
import MarchandList from '../../core/sysext/Boutique/containers/Marchand/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const MarchandsPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        <MarchandForm docId={star} {...props} />
      ) : (
        <MarchandList {...props} />
      )}
    </Backoffice>
  );
};

export default MarchandsPage;
